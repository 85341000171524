import { ClerkApp } from "@clerk/remix";
import "react-loading-skeleton/dist/skeleton.css";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { init, isInitialized } from "@fullstory/browser";
import {
  type LinkDescriptor,
  type LinksFunction,
  type MetaFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import React from "react";
import { ExternalScripts } from "remix-utils/external-scripts";
import { Toaster } from "~/components/ui/toaster";
import tailwindStylesheetUrl from "~/tailwind.css?url";
import { getPublicEnvs } from "~/utils/env.server";
import { useGoogleTagManager } from "~/utils/useGoogleTagManager";
import { GeneralErrorBoundary } from "./components/error-boundary";
import { AddBusinessProvider } from "./routes/business+/add/context/addBusinessProvider";
import { typedjson, useTypedLoaderData } from "remix-typedjson";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const links: LinksFunction = () => {
  return [
    { rel: "preload", href: tailwindStylesheetUrl, as: "style" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
    { rel: "manifest", href: "/site.webmanifest" },
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    // NOTE: Not sure why typescript isn't picking up on the filter here so it is explicitly cast
  ].filter(Boolean) as LinkDescriptor[];
};

export const meta: MetaFunction = () => {
  return [
    { title: "Hansa" },
    { name: "description", content: "Find your business, fix your data" },
  ];
};

export async function loader(args: any) {
  const ENV = getPublicEnvs();
  const stringEnv = JSON.stringify(ENV);

  return rootAuthLoader(args, async () =>
    typedjson({
      ENV,
      stringEnv,
    }),
  );
}

function App() {
  // Get the locale from the loader
  let loaderData = useTypedLoaderData<typeof loader>();
  let { ENV, stringEnv } = loaderData;

  // Insert Google Tag Manager script tag
  useGoogleTagManager("GTM-PLW3PGJ", ENV.MODE);

  React.useEffect(() => {
    const enabled = ENV.MODE === "production";

    if (enabled && !isInitialized()) {
      init({ orgId: "o-1RAW5J-na1", devMode: !enabled }, () =>
        console.log("FullStory is ready!"),
      );
    }
  }, [ENV.MODE]);

  return (
    <html className="h-full">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>

      <body
        id="root-content"
        className="m-0 flex min-h-screen w-full flex-col justify-between bg-white p-0"
      >
        <GoogleOAuthProvider clientId={ENV.GOOGLE_CLIENT_ID}>
          <AddBusinessProvider>
            <Outlet />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${stringEnv}`,
              }}
            />

            <Toaster />
            <ScrollRestoration />
            <ExternalScripts />
            <Scripts />
          </AddBusinessProvider>
        </GoogleOAuthProvider>
        {ENV.MODE === "staging" ||
          (ENV.MODE === "production" && (
            <noscript>
              <iframe
                title="gtm-no-script"
                src="https://www.googletagmanager.com/ns.html?id=GTM-PLW3PGJ"
                height="0"
                width="0"
                className="invisible hidden"
              ></iframe>
            </noscript>
          ))}
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  return (
    <html className="h-full">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="h-full w-full bg-white">
        <GeneralErrorBoundary />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default Sentry.withSentry(ClerkApp(App));
